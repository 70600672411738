<template>
  <div>
    <v-divider :dark="tenant.toolbarDark" />

    <v-container
      fluid
      class="d-flex flex-row align-start pa-0 align-stretch event-container"
    >

        <div v-if="showNotFoundOrNoPermissionsError" class="content-container mt-16">
          <v-alert type="warning" prominent icon="fa-lock">
            <h3>Organization not found or No Permissions to manage</h3>
            <p>Either the event does not exit or you do not have permissions to manage it. Please make sure you are logged in with the correct account.</p>
            <p class="mt-8 mb-0">Id:</p>
            <code>{{$route.params.id}}</code>
            <p class="mt-8">
              <v-btn :to="{ name: 'eventmanager'}">My Events</v-btn>
            </p>
          </v-alert>
        </div>
        <div v-if="!showNotFoundOrNoPermissionsError" class="grey lighten-4 pa-0 content-container">
            <v-toolbar dense elevation="0" color="white lighten-4 width-100p" >
            <v-toolbar-title class="ml-4"><router-link class="no-decoration" :to="{name:'orgmanagerView', params: {id: org.id}}">{{org.name}}</router-link></v-toolbar-title>
            <v-spacer></v-spacer>
            <div>
              <v-btn v-if="org" outlined :to="{name:'orgmanagerView', params: {id: org.id}}" class="mr-2">
                <v-icon small class="mr-2">fadl fa fa-eye</v-icon>
                Back-end
              </v-btn>
            </div>
            </v-toolbar>

            <v-card class="ma-4 pa-0">
              <router-view  :tenant="tenant" :org="org"></router-view>
            </v-card>
        </div>
    </v-container>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import orgManagerService from "@/services/orgManagerService";
import siteData from '@/data/site.json'
import OrgUtil from "@/util/orgUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "OrgLayoutBase",
  components: {
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      org: null,
      siteData: siteData,
      showNotFoundOrNoPermissionsError: false,
    };
  },
  async mounted() {
    this.mini = this.$cookies.isKey('em.menu.mini');
    this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      this.loadData();
    });
    EventBus.$on('orgmanager-request-save', async () => {
      const saved = await this.orgUtil.save();
      if (saved) {
        this.org = this.orgUtil.org; // force refresh down the tree
      }
    });
    EventBus.$on('orgmanager-refresh', async state => {
        console.log('refresh requested', state);
        this.org = state.org; // force refresh down the tree
    });    
    EventBus.$on('http-error', async response => {
        if (response.status == 401) {
          this.$helpers.toastResponse(this, { status: "ERROR", msg: "Unauthorized: no permissions to complete this request."});
        }
    });
  },
  methods: {
    async loadData() {
      if (this.user) {
        await this.getOrg(this.$route.params.id);
      }
    },
    async getOrg(id) {
      try {
        this.org = (await orgManagerService.get(id)).data;
        this.showNotFoundOrNoPermissionsError = false;
      }
      catch {
        this.showNotFoundOrNoPermissionsError = true;
      }
    },
   
  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    orgUtil() {
      return new OrgUtil(this, this.org);
    },
    breadcrumbItems() {
      return !this.org ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style lang="scss">
    .width-100p { width: inherit !important;}
    .event-container {
      width: inherit !important;

      .content-container {
        flex-grow: 1;
        min-width: 400px;
      }
    }
    .info-container {
        .label { font-size: 80%; opacity: .8; }
        .value { margin-bottom: 10px; }
        .value pre { white-space: pre-wrap;}
        /*.value:last-child { margin-bottom: 0px; ;}*/
    }

</style>

